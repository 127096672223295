import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper wrapper_space-top"},[_c('div',{staticClass:"d-flex"},[(_vm.isRequestLinkTalent)?_c('div',{staticClass:"align-self-center me-2"},[_c(VIcon,{attrs:{"dark":"","color":"primary","size":"30"},on:{"click":function($event){_vm.isRequestLinkTalent = false}}},[_vm._v(" mdi-arrow-left-bold-circle-outline ")])],1):_vm._e(),_c('h1',{staticClass:"pageHeader"},[_vm._v(" "+_vm._s(_vm.isRequestLinkTalent ? 'Add Talent ' : 'Talents Account')+" ")])]),(!_vm.isRequestLinkTalent)?[_c('div',{staticClass:"d-flex mb-12 mt-5"},[_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentTab == 'talents' ? 'active ' : '',on:{"click":function($event){_vm.currentTab = 'talents'}}},[_vm._v(" Connected ("+_vm._s(_vm.lists.talents.length)+") ")]),_c(VBtn,{staticClass:"tabBtn",class:_vm.currentTab == 'pendings' ? 'active ' : '',on:{"click":function($event){_vm.currentTab = 'pendings'}}},[_vm._v(" Pending ("+_vm._s(_vm.lists.pendings.length)+") ")]),_c(VIcon,{staticClass:"dark ml-auto me-3",attrs:{"color":"primary","size":"40"},on:{"click":function($event){_vm.isRequestLinkTalent = true}}},[_vm._v(" mdi-plus-circle ")]),_c('div',{staticClass:"align-self-center"},[_c(VMenu,{attrs:{"dark":"","left":"","z-index":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"icon",attrs:{"color":"white"}},on),[_vm._v(" mdi-dots-vertical ")])]}}],null,false,3067116039)},[_c(VList,[(_vm.currentTab == 'talents')?_c(VListItem,{on:{"click":function($event){_vm.isSelect = true;
                _vm.processType = 'remove';}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Remove Talent")])],1):_vm._e(),(_vm.currentTab == 'pendings')?_c(VListItem,{staticStyle:{"border-bottom":"1px solid grey"},on:{"click":function($event){_vm.isSelect = true;
                _vm.processType = 'reject';}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Reject Request")])],1):_vm._e(),(_vm.currentTab === 'pendings')?_c(VListItem,{on:{"click":function($event){_vm.isSelect = true;
                _vm.processType = 'approve';}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Approve Request")])],1):_vm._e()],1)],1)],1)],1),_c('AppSearchBar',{staticClass:"mt-10",attrs:{"search":_vm.loadSingleDataDebounce},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mb-2 d-flex"},[(!_vm.isSelect)?_c('div',[_vm._v("("+_vm._s(_vm.lists[_vm.currentTab].length || 0)+")")]):_c('div',[_vm._v("("+_vm._s(_vm.requestId.length || 0)+")")]),(_vm.isSelect)?_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.isSelect = false}}},[_vm._v("Cancel")]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.process.type === 'remove'
              ? _vm.$openModal('confirmRemoveModal')
              : _vm.processTalents()}}},[_vm._v(" "+_vm._s(_vm.process.title)+" ")])]):_vm._e()]),_c('UserContainerList',{attrs:{"lists":_vm.lists[_vm.currentTab],"custom":{
        hasCharacteristic: true,
        nameCol: true
      },"photoKey":"headshot_file","hasSelect":_vm.isSelect,"selectIdKey":"request_id","searching":_vm.searching},on:{"select":function($event){_vm.requestId = $event}},scopedSlots:_vm._u([{key:"name-col",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"titleFont grey--text"},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.fname)+" ")])]}}],null,false,2687043758),model:{value:(_vm.requestId),callback:function ($$v) {_vm.requestId=$$v},expression:"requestId"}}),_c('div',{staticClass:"text-center my-12 pb-12"},[_c('div',[_c(VBtn,{staticClass:"btn1 mb-5",attrs:{"disabled":!_vm.enableShowMore[_vm.currentTab]},on:{"click":function($event){return _vm.loadSingleData(true)}}},[_vm._v(" Show More ")])],1),_c('div',{staticClass:"underlineBtn mt-4 pb-12",on:{"click":function($event){return _vm.$scrollToTop()}}},[_vm._v(" Back to top ")])])]:_c('RequestLinkTalentList'),_c('ModalBase',{attrs:{"id":"confirmRemoveModal"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-uppercase font-weight-bold",staticStyle:{"font-size":"1.4rem"}},[_vm._v(" Profile ("+_vm._s(_vm.requestId.length)+") will be permanently removed and will no longer be linked to the company profile. ")]),_c(VBtn,{staticClass:"btn1 mt-12",on:{"click":function($event){_vm.processTalents();
          _vm.$closeModal();}}},[_vm._v(" Remove ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }