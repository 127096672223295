<template>
  <div class="container wrapper wrapper_space-top">
    <div class="d-flex">
      <div v-if="isRequestLinkTalent" class="align-self-center me-2">
        <v-icon dark color="primary" size="30" @click="isRequestLinkTalent = false">
          mdi-arrow-left-bold-circle-outline
        </v-icon>
      </div>
      <h1 class="pageHeader" >
        {{ isRequestLinkTalent ? 'Add Talent ' : 'Talents Account' }}
      </h1>
    </div>

    <template v-if="!isRequestLinkTalent">
      <div class="d-flex mb-12 mt-5">
        <v-btn
          :class="currentTab == 'talents' ? 'active ' : ''"
          class="tabBtn me-5"
          @click="currentTab = 'talents'"
        >
          Connected ({{ lists.talents.length }})
        </v-btn>
        <v-btn
          :class="currentTab == 'pendings' ? 'active ' : ''"
          class="tabBtn"
          @click="currentTab = 'pendings'"
        >
          Pending ({{ lists.pendings.length }})
        </v-btn>
        <v-icon
          color="primary"
          class="dark ml-auto me-3"
          size="40"
          @click="isRequestLinkTalent = true"
        >
          mdi-plus-circle
        </v-icon>
        <div class="align-self-center">
          <v-menu dark left z-index="0" offset-y>
            <template v-slot:activator="{ on }">
              <v-icon color="white" class="icon" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-if="currentTab == 'talents'"
                @click="
                  isSelect = true;
                  processType = 'remove';
                "
              >
                <v-list-item-title class="px-8">Remove Talent</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="currentTab == 'pendings'"
                @click="
                  isSelect = true;
                  processType = 'reject';
                "
                style="border-bottom: 1px solid grey"
              >
                <v-list-item-title class="px-8">Reject Request</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="currentTab === 'pendings'"
                @click="
                  isSelect = true;
                  processType = 'approve';
                "
              >
                <v-list-item-title class="px-8"
                  >Approve Request</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        
      </div>
      <AppSearchBar
        v-model="search"
        :search="loadSingleDataDebounce"
        class="mt-10"
      />
      <div class="mb-2 d-flex">
        <div v-if="!isSelect">({{ lists[currentTab].length || 0 }})</div>
        <div v-else>({{ requestId.length || 0 }})</div>
        <div class="ml-auto" v-if="isSelect">
          <span class="cursor-pointer" @click="isSelect = false">Cancel</span>
          <span class="mx-5">|</span>
          <span
            class="cursor-pointer"
            @click="
              process.type === 'remove'
                ? $openModal('confirmRemoveModal')
                : processTalents()
            "
          >
            {{ process.title }}
          </span>
        </div>
      </div>
      <UserContainerList
        :lists="lists[currentTab]"
        :custom="{
          hasCharacteristic: true,
          nameCol: true
        }"
        photoKey="headshot_file"
        :hasSelect="isSelect"
        selectIdKey="request_id"
        v-model="requestId"
        :searching="searching"
        @select="requestId = $event"
      >
        <template #name-col="{ item }">
          <div class="titleFont grey--text">
            {{ item.display_name }}
          </div>
          <div>
            {{ item.fname }}
          </div>
        </template>
      </UserContainerList>
      <div class="text-center my-12 pb-12">
        <div>
          <v-btn
            class="btn1 mb-5"
            @click="loadSingleData(true)"
            :disabled="!enableShowMore[currentTab]"
          >
            Show More
          </v-btn>
        </div>
        <div class="underlineBtn mt-4 pb-12" @click="$scrollToTop()">
          Back to top
        </div>
      </div>
    </template>
    <RequestLinkTalentList v-else />
    <ModalBase id="confirmRemoveModal">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold" style="font-size: 1.4rem">
          Profile ({{ requestId.length }}) will be permanently removed and will
          no longer be linked to the company profile.
        </div>
        <v-btn
          class="btn1 mt-12"
          @click="
            processTalents();
            $closeModal();
          "
        >
          Remove
        </v-btn>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import { PROFILE } from '@/api';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import ModalBase from '@/components/base/ModalBase';
  import RequestLinkTalentList from '@/components/profile/RequestLinkTalentList';
  import { ROUTE_NAME } from '@/constants';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    name: 'TalentAccount',
    components: {
      ModalBase,
      RequestLinkTalentList,
      UserContainerList
    },
    data: () => ({
      lists: {
        talents: [],
        pendings: []
      },
      currentTab: 'talents',
      search: '',
      searching: false,
      requestId: [],
      isSelect: false,
      processType: '',
      process: null,
      routeName: ROUTE_NAME,
      isRequestLinkTalent: false,
      perPage: 10,
      enableShowMore: {
        talents: false,
        pendings: false
      }
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      }
    },
    watch: {
      // search(val) {
      //   const tab = this.currentTab;
      //   if(val){
      //     let selectedList = this.lists[tab];
      //     const filteredItems = selectedList.filter((x) => {
      //       const columns = x.first_name + x.last_name
      //       return val.toLowerCase().split(' ').every(v => columns.toLowerCase().includes(v));
      //     })
      //     this.lists[tab] = filteredItems;
      //     // return this.resources.filter((item)=>{
      //     //   return item.title.startsWith(this.searchQuery);
      //     // })
      //   }else{
      //       this.lists[tab] = _.cloneDeep(this.oriLists[tab])
      //   }
      // },
      processType(val) {
        switch (val) {
          case 'remove':
            this.process = {
              type: 'remove',
              method: 'removeTalent',
              title: 'Remove',
              list: 'talents'
            };
            break;
          case 'approve':
            this.process = {
              type: 'approve',
              method: 'approveTalentRequest',
              title: 'Approve',
              list: 'pendings'
            };
            break;
          case 'reject':
            this.process = {
              type: 'reject',
              method: 'rejectTalentRequest',
              title: 'Reject',
              list: 'pendings'
            };
            break;
          default:
            this.process = null;
        }
      },
      isSelect(val) {
        if (!val) {
          this.requestId = [];
          this.processType = '';
        }
      },
      currentTab() {
        this.isSelect = false;
        this.perPage = 10;
        this.search = '';
      },
      isRequestLinkTalent(val) {
        this.$scrollToTop();
        if (!val) {
          this.loadData();
        }
      }
    },
    methods: {
      async loadData() {
        const query = {
          per_page: this.perPage,
          search: this.search
        };
        await this.getTalents(query);
        await this.getPendingTalents(query);
      },
      loadSingleDataDebounce() {
        this.$debounceHandler(() => {
          this.loadSingleData()
        });
      },
      async loadSingleData(addPagination = false) {
        this.perPage = addPagination ? this.perPage + 10 : this.perPage;
        const query = {
          per_page: this.perPage + 1,
          search: this.search
        };
        if (this.currentTab == 'talents') await this.getTalents(query);
        else await this.getPendingTalents(query);
      },
      async getPendingTalents(query) {
        this.searching = true;
        try {
          const pendingTalentsData = await PROFILE.getPendingTalents(query);
          this.lists = {
            ...this.lists,
            pendings: pendingTalentsData?.data.items || []
          };
          if (this.lists.pendings.length > this.perPage) {
            this.lists.pendings.pop();
            this.enableShowMore.pendings = true;
          } else {
            this.enableShowMore.pendings = false;
          }
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.searching = false;
      },
      async getTalents(query) {
        this.searching = true;
        try {
          const talentsData = await PROFILE.getTalents(query);
          this.lists = {
            ...this.lists,
            talents: talentsData?.data.items || []
          };
          if (this.lists.talents.length > this.perPage) {
            this.lists.talents.pop();
            this.enableShowMore.talents = true;
          } else {
            this.enableShowMore.talents = false;
          }
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.searching = false;
      },

      getProfileItems(item) {
        let languages = '';

        if (item.profile?.languages_spoken) {
          item.profile?.languages_spoken?.forEach((x, index, arr) => {
            const language = this.constantsData.language_spoken.find(
              (y) => y.value === x
            );
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item.profile?.height || ''
          },
          {
            title: 'Race',
            value: item.profile?.race || ''
          },
          {
            title: 'Language',
            value: languages || ''
          },
          {
            title: 'Skills',
            value: item.profile?.skills || ''
          }
        ];
      },
      async processTalents() {
        this.$startLoading();
        try {
          const res = await PROFILE[this.process.method]({ requestId: this.requestId });
          this.loadData();
          this.lists[this.process.list] = this.list;
          this.requestId = [];
          this.openAppDialogInfo(
            'success',
            res.msg,
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
          
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      }
    },
    mounted() {
      this.$startLoading();
      this.loadData();
      this.$stopLoading();
      if (this.$route.params?.tab) this.currentTab = this.$route.params?.tab;
    }
  };
</script>

<style scoped lang="scss">
  .searchBar {
    height: 45px;
    font-size: 1rem;
    width: 95%;
    background-color: #242424;
    padding: 10px 2px;
    border-radius: 20px;
    color: #ffffff;
  }
</style>
