<template>
  <div>
    <AppSearchBar
      v-model="search"
      :search="getUnlinkedTalentsDebounce"
      class="mt-10"
    />
    <div class="mb-4 d-flex">
      <div class="align-self-center">({{ selectedIds.length }})</div>
      <div class="ml-auto" v-if="selectedIds.length">
        <span class="pink2--text addTalentFont" @click="requestLinkTalent">
          Add
        </span>
      </div>
    </div>
    <UserContainerList
      v-model="selectedIds"
      :lists="list"
      :custom="{
        hasCharacteristic: true,
      }"
      nameKey="fname"
      photoKey="headshot_file"
      hasSelect
      :searching="searching"
      :perPage="perPage"
      @select="selectedIds = $event"
    >
    </UserContainerList>
    <div class="text-center my-12 pb-12">
      <div>
        <v-btn class="btn1 mb-5" @click="showMore" :disabled="!enableShowMore">
          Show More
        </v-btn>
      </div>
      <div class="underlineBtn mt-4 pb-12" @click="$scrollToTop()">
        Back to top
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PROFILE_INITIAL_GET_UNLINKED_TALENTS_STATE,
    PROFILE_GET_UNLINKED_TALENTS,
    PROFILE_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE,
    PROFILE_CREATE_TALENT_LINK_REQUEST
  } from '@/store/profile.module';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import { ROUTE_NAME } from '@/constants';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    components: {
      UserContainerList
    },
    data() {
      return {
        list: [],
        search: '',
        searching: false,
        selectedIds: [],
        routeName: ROUTE_NAME,
        defaultProfileImage: this.$store.getters['constants/avatarImageUrl'],
        perPage: 10,
        enableShowMore: false
      };
    },
    mounted() {
      this.getUnlinkedTalents(true);
    },
    computed: {
      getUnlinkedTalentsComplete() {
        return this.$store.state.profile.unlinkedTalents.complete;
      },
      createTalentLinkRequestComplete() {
        return this.$store.state.profile.createTalentLinkRequest.complete;
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      }
    },
    watch: {
      getUnlinkedTalentsComplete() {
        let response = this.$store.state.profile.unlinkedTalents;
        if (response.complete) {
          this.importUnlinkedTalentList(response);
        }
      },
      createTalentLinkRequestComplete() {
        let response = this.$store.state.profile.createTalentLinkRequest;
        if (response.complete) {
          this.createTalentLinkRequestCompleteAction(response);
        }
      },
    },
    methods: {
      getUnlinkedTalentsDebounce() {
        this.$debounceHandler(() => {
          this.perPage = 10;
          this.getUnlinkedTalents();
        });
      },
      importUnlinkedTalentList(response) {
        if (response.code == 200) {
          this.list = response.data.items;
          if (this.list.length > this.perPage) {
            this.list.pop();
            this.enableShowMore = true;
          } else {
            this.enableShowMore = false;
          }
        }
        this.searching = false;
        this.$store.dispatch(PROFILE_INITIAL_GET_UNLINKED_TALENTS_STATE);
      },
      getUnlinkedTalents(firstLoad = false) {
        let query = {
          search: this.search,
          per_page: this.perPage + 1
        };
        this.searching = true;
        this.$store.dispatch(PROFILE_GET_UNLINKED_TALENTS, {
          query,
          firstLoad
        });
      },
      createTalentLinkRequestCompleteAction(response) {
        if (response.code == 200) {
          this.getUnlinkedTalents();
          this.$openModal(
            'messageModal',
            'Request has been sent to talent(s)',
            {
              title: 'Done',
              action: () => {
                this.selectedIds = [];
                this.$closeModal();
              }
            }
          );
        } else {
          this.$openModal('messageModal', response.message);
        }

        this.$store.dispatch(PROFILE_INITIAL_CREATE_TALENT_LINK_REQUEST_STATE);
      },
      requestLinkTalent() {
        this.$openModal('messageModal', 'Are you sure to proceed?', {
          title: 'Proceed',
          action: () => {
            this.$store.dispatch(PROFILE_CREATE_TALENT_LINK_REQUEST, {
              data: this.selectedIds
            });
            this.$closeModal();
          }
        });
      },
      getProfileItems(item) {
        let languages = '';

        if (item.profile?.languages_spoken) {
          item.profile?.languages_spoken?.forEach((x, index, arr) => {
            const language = this.constantsData.language_spoken.find(
              (y) => y.value === x
            );
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item.profile?.height || ''
          },
          {
            title: 'Race',
            value: item.profile?.race || ''
          },
          {
            title: 'Language',
            value: languages || ''
          },
          {
            title: 'Skills',
            value: item.profile?.skills || ''
          }
        ];
      },
      getProfileImage(profile) {
        return profile?.headshot_file?.media_path || this.defaultProfileImage;
      },
      showMore() {
        this.perPage += 10;
        this.getUnlinkedTalents();
      }
    }
  };
</script>

<style scoped>
  .searchBar {
    height: 45px;
    font-size: 1rem;
    width: 95%;
    background-color: #242424;
    padding: 10px 2px;
    border-radius: 20px;
    color: #ffffff;
  }
  .userContainer {
    background-color: #242424;
    border-radius: 5px;
  }
  .titleFont {
  }
  .lg-font {
    font-size: 1.4rem;
  }

  .clickable {
    cursor: pointer;
  }

  .addTalentFont {
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
</style>
